import { ofType, StateObservable } from 'redux-observable';
import { useDispatch, useSelector } from 'react-redux';
import * as rxjs from 'rxjs';
import * as uiDuck from './uiDuck';
import { Elements, FilterChain, NeoReturnElements, OneLeft, ToolEventTypes } from '../../types/types';
import { Action, AnyAction, Dispatch } from 'redux';

import * as operators from 'rxjs';
import { Observable } from 'rxjs';
import * as rxws from 'rxjs/webSocket';
import * as _ from 'lodash';

import { gql } from '@apollo/client';
import { filter } from 'd3';
import { cloneDeep } from '@apollo/client/utilities';
import * as helper from '../helper';

import { types as userActionTypes } from './userDuck';
import { RootState } from '../rootState';
import Types from '../../types/types';
import { act } from 'react-dom/test-utils';
import { buildCommonValuesAlternative } from '../doit';


export interface Relationships {
  source: string
  target: string
  id: string
  [key: string]: string | Relationships
}


export interface DataGridReport {
  type: 'datagrid'
  data: {
    rows: any[]
    cols: any[]
  }
}

interface EmptyReport {
  type: string // Can be anything other than "datagrid"
  data: {}
}

export type ReportState = DataGridReport | EmptyReport;

export interface screenSettingData {
  [key: string]: {
    mandatory: boolean
    type: string
    array: boolean
    checkIfValid: (value: Object) => boolean
    values: {
      changeFunction: Function
      type: string
      min: string
      max: string | null
      default: string
      selected: string | null
    }
  }
}
export interface InitialState {
  meta: {
    original: cytoscape.ElementDataDefinition[]
  }
  typeDefaults: { [key: string]: Object }
  styles: any
  distinctRoles?: any 
  config?: any
  roles: Array<string>
  dictionary: Array<Object>
  filter: helper.StorageFilterCombo[]
  highlight: Array<any>
  theleft: OneLeft[]
  uiList: cytoscape.ElementDefinition[]
  // uiList: {id?: string, [key:string|number]: }
  structure: Elements
  current_page: number
  reportdata: ReportState[]
  carouselpages: { id: number }[]
  mappedTechnicalDependencies: {
    [key: string]: { [key: string]: { [key: string]: string | undefined } }
  }
  customerWarning: {
    show: boolean
    decision: boolean | null
    content: { text: string; list: string[] | null }
  }
  promise: { promise: Promise<any>; resolve: Function; reject: Function } | null
  commonvalues: { [key: string]: uiDuck.field_type } | null
}

const name = 'ui';
export const types = {
  NAME: `${name}`,
  LOAD: '${NAME}/LOAD',
  SAVE: '${NAME}/SAVE',
  DELETE: '${NAME}/DELETE',
  ARRIVED: '${NAME}/ARRIVED',
  ADD_CATEGORY_REQUEST: '${NAME}/ADD_CATEGORY_REQUEST',
  ADD_CATEGORY_SUCCESS: '${NAME}/ADD_CATEGORY_SUCCESS',
  GET_CATEGORIES_REQUEST: '${NAME}/GET_CATEGORIES_REQUEST',
  ADD_CATEGORY_ERROR: '${NAME}/ADD_CATEGORY_ERROR',
  START_BANK_STREAM: '${NAME}/START_BANK_STREAM',
  BANK_STREAM_MESSAGE: '${NAME}/BANK_STREAM_MESSAGE',
  STOP_BANK_STREAM: '${NAME}/STOP_BANK_STREAM',

  // jetzt gilts
  SHOWMETA: `${name}/SHOWMETA`,
  HIGHLIGHT: `${name}/HIGHLIGHT`,
  UPDATETHELEFT: `${name}/UPDATETHELEFT`,
  UPDATEUILIST: `${name}/UPDATEUILIST`,
  DELETEUILIST: `${name}/DELETEUILIST`,
  WARNING: `${name}/WARNING`,
  PROMISE: `${name}/PROMISE`,

  ADDFILTER: `${name}/ADDFILTER`,
  BACKEND_ADDFILTER: `${name}/BACKEND_ADDFILTER`,
  UPDATEDICT: `${name}/UPDATEDICT`,
  TURNPAGE: `${name}/TURNPAGE`,
  REMOVEPAGE: `${name}/REMOVEPAGE`,
  CHANGE_STYLES: `${name}/CHANGE_STYLES`,
  BATCH_STYLES: `${name}/BATCH_STYLES`,
  CONFIG: `${name}/CONFIG`,
  CONFIG_CHANGED: `${name}/CONFIG_CHANGED`,
  CAROUSEL: `${name}/CAROUSEL`,
  REPORTDATA: `${name}/REPORTDATA`,
  REPLACETECHNICALDEPENDENCIES: `${name}/REPLACETECHNICALDEPENDENCIES`,
  SETCOMMONVALUES: `${name}/SETCOMMONVALUES`,
};

// selector functions
export const select = {
  mappedTechnicalDependencies: (state: RootState) =>
    state.uiApp.mappedTechnicalDependencies,
  customerWarning: (state: RootState) => state.uiApp.customerWarning,
  meta: (state: RootState) => state.uiApp.meta.original,
  typeDefaults: (state: RootState) => state.uiApp.typeDefaults,
  metaAll: (state: RootState) => state.uiApp.meta,
  structure: (state: RootState) => state.uiApp.structure,
  filters: (state: RootState) => state.uiApp.filter,
  highlighted: (state: RootState) => state.uiApp.highlight,
  dictionary: (state: RootState) => state.uiApp.dictionary,
  theleft: (state: RootState) => state.uiApp.theleft,
  uiList: (state: RootState) => state.uiApp.uiList,

  // contains common values for editcard
  commonvalues: (state: RootState) => state.uiApp.commonvalues,

  // contains roles which are also part of dictionary
  roles: (state: RootState) => state.uiApp.distinctRoles,
  // contains the current page number for the filters
  currentpage: (state: RootState) => state.uiApp.current_page,
  // contains dynamic content for report page
  report: (state: RootState) => state.uiApp.reportdata,
  //
  carouselpages: (state: RootState) => state.uiApp.carouselpages,
  // contains the graphs styles
  stylesheet: (state: RootState) => state.uiApp.styles,
  // contains the graphs styles
  config: (state: RootState) => state.uiApp.config,
  // contains the promise for warning
  promise: (state: RootState) => state.uiApp.promise,
};

// These functions are being called from code, so they need to have comments
export const dispatcher = {
  /**
   * value will be dispatched to meta.original.
   * value's id's will be marked to be highlighted
   * */
  set_commonvalues: (
    dispatch: Dispatch<AnyAction>,
    value: { [key: string]: uiDuck.field_type },
  ) => {
    dispatch(actions.action_set_commonvalues(value));
  },
  /**
   * value will be dispatched to meta.original.
   * value's id's will be marked to be highlighted
   * */
  replace_technical_dependencies: (
    dispatch: Dispatch<AnyAction>,
    value: {
      type: string
      dependingProp: string
      dependingOn: string
      dependingVals: string[]
    },
  ) => {
    dispatch(actions.replace_technical_dependencies_action(value));
  },
  /**
   * value will be dispatched to meta.original.
   * value's id's will be marked to be highlighted
   * */
  toggle_meta_view: (dispatch: Dispatch, value: cytoscape.ElementDataDefinition[] | []) => {
    console.log('dispatching action to updating the meta now', value);
    console.trace('toggle_meta_view');
    dispatch(actions.showMeta(value));
  },
  update_warning: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.update_warning(value));
  },
  set_promise: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.set_promise(value));
  },
  update_theleft: (dispatch: Dispatch, value: {nodes: Object[], edges: Object[]}) => {
    console.log('dispatching action to updating the left now in uiduck', value);
    console.trace('toggle_meta_view');
    const newnodes = value.nodes.map((node: any) => ({ data: node }));
    dispatch(actions.updateTheLeft({ edges: value.edges, nodes: newnodes }));
  },
  //
  crud_uiListElements: (dispatch: Dispatch, value: cytoscape.ElementDefinition[]) => {
    console.log('dispatching action to updating the left now in uiduck', value);
    console.trace('toggle_meta_view');
    // const newnodes = value.nodes.map(node => ({data:node}))
    dispatch(actions.updateUiListElements(value));
  },
  d_uiListElements: (dispatch: Dispatch, value: Object) => {
    console.log('dispatching action to updating the left now in uiduck', value);
    console.trace('toggle_meta_view');
    // const newnodes = value.nodes.map(node => ({data:node}))
    dispatch(actions.deleteUiListElements(value));
  },
  // value is array of element-ids
  highlight: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.highlightElements(value));
  },
  showMeta: (dispatch: Dispatch, value: cytoscape.ElementDataDefinition[]) => {
    dispatch(actions.showMeta(value));
  },
  // filterElements: (dispatch: Dispatch, value: Object) => {
  //   console.log('Filter the Elements for Role ', value);
  //   dispatch(actions.filterRolesClick(value));
  // },
  changeStyles: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.changeStyles(value));
  },
  updateDictionary: (dispatch: Dispatch, value: Object) => {
    console.log('updating dict now', value);
    dispatch(actions.updateDictionary(value));
  },
  // adds filter provided in value to filters already present
  // if none is provided, all are reset
  filters: (dispatch: Dispatch, value: {crud: string, filterchain?: FilterChain, storageFilterCombo?: helper.StorageFilterCombo | helper.CyFilterCombo }) => {
    console.log('weareherenow xxa',{value})
    dispatch(actions.filters(value));
  },
  // changes to different page
  turnpage: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.turnpage(value));
  },
  // deletes page
  removepage: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.removepage(value));
  },
  // carouselpages
  changecarouselpages: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.changecarouselpages(value));
  },
  // changes to different page
  config: (dispatch: Dispatch, value: Object) => {
    dispatch(actions.config(value));
  },
};

// action creator
export const actions = {
  saveClick: (value: Object) => ({ type: types.SAVE, payload: value }),
  loadClick: (value: Object) => ({ type: types.LOAD, payload: value }),

  // jetzt gilts

  action_set_commonvalues: (
    value: { [key: string]: uiDuck.field_type } = {},
  ) => {
    console.trace('what is updating this');
    return { type: types.SETCOMMONVALUES, payload: value };
  },
  replace_technical_dependencies_action: (
    value: {
      type: string
      dependingProp: string
      dependingOn: string
      dependingVals: string[]
    },
  ) => {
    return { type: types.REPLACETECHNICALDEPENDENCIES, payload: value };
  },
  showMeta: (value: cytoscape.ElementDataDefinition[] = []) => {
    console.log('showMeta helloworld here', value);
    return { type: types.SHOWMETA, payload: value };
  },
  update_warning: (value: Object) => ({ type: types.WARNING, payload: value }),
  set_promise: (value: Object) => ({ type: types.PROMISE, payload: value }),
  updateTheLeft: (value: Object) => {
    console.log('updatetheleft helloworld here', value);
    //is used in load etc
    return { type: types.UPDATETHELEFT, payload: value };
  },
  updateUiListElements: (value: cytoscape.ElementDefinition[]) => {
    console.error('Check again that the correct values are transmitted from epic. val.data instead of just val?!')
    console.log('uipdatethelist helloworld here', value);
    //is used in load etc
    return { type: types.UPDATEUILIST, payload: value };
  },
  deleteUiListElements: (value: Object) => {
    console.log('uipdatethelist helloworld here', value);
    //is used in load etc
    return { type: types.DELETEUILIST, payload: value };
  },
  /**
   * Applies Styles, mostly used for Pictures in Node Backgrounds
   * @param value 
   * @returns 
   */
  batchStyle: (value: NeoReturnElements['props']['nodes']) => ({ type: types.BATCH_STYLES, payload: value }),
  changeStyles: (value: Object) => ({ type: types.CHANGE_STYLES, payload: value }),
  highlightElements: (value: Object) => ({ type: types.HIGHLIGHT, payload: value }),
  // roles: (value: Object) => ({ type: types.ROLE, payload: value }),

  // adds filter provided in value to filters already present
  // if none is provided, all are reset
  /**
   *
   * @param value: {crud:'c,r,u,d', filterchain: [ {filter: {col:, comp:, val:}, param:{ not:false, op:'and/or/xor' }} ]}
   * @returns
   */
  // filters: (value: {crud: any, filterchain: any}) => ({ type: types.ADDFILTER, payload: value }),

  // StorageFilterCombo could be handed in directly as well, with a separate flag to process that
  filters: (value: {crud: string, filterchain?: FilterChain, storageFilterCombo?: helper.StorageFilterCombo | helper.CyFilterCombo }) => ({ type: types.ADDFILTER, payload: value }),
  updateDictionary: (value: Object) => ({ type: types.UPDATEDICT, payload: value }),
  turnpage: (value: Object) => ({ type: types.TURNPAGE, payload: value }),
  removepage: (value: Object) => ({ type: types.REMOVEPAGE, payload: value }),
  changecarouselpages: (value: Object) => ({ type: types.CAROUSEL, payload: value }),
  config: (value: Object) => ({ type: types.CONFIG, payload: value }),
};


let size = 100;
if (typeof window !== 'undefined') {
  let size = window.innerWidth / 10;
}

const nodes: cytoscape.NodeDefinition[] = [
  {
    data: { id: 'not urgent', label: 'not urgent', type: 'structure' },
    position: {
      x: -1000,
      y: 0,
    },
    style: {
      'background-color': 'grey',
    },
    locked: true,
    grabbable: false,
  },
  {
    data: { id: 'urgent', label: 'urgent', type: 'structure' },
    position: {
      x: 1000,
      y: 0,
    },
    style: {
      'background-color': 'grey',
    },
    locked: true,
    grabbable: false,
  },
  {
    data: { id: 'unimportant', label: 'unimportant', type: 'structure' },
    position: {
      x: 0,
      y: size,
    },
    style: {
      'background-color': 'grey',
    },
    locked: true,
    grabbable: false,
  },
  {
    data: { id: 'important', label: 'important', type: 'structure' },
    position: {
      x: 0,
      y: -size,
    },
    style: {
      'background-color': 'grey',
    },
    locked: true,
    grabbable: false,
  },
];
const edges: cytoscape.EdgeDefinition[] = [
  {
    data: {
      id: 'xaxis',
      source: 'unimportant',
      target: 'important',
      type: 'structure',
    },
  },
  {
    data: {
      id: 'yaxis',
      source: 'urgent',
      target: 'not urgent',
      type: 'structure',
    },
  },
];
const eles = { nodes, edges };

const styles = [
  {
    selector: 'node',
    css: {
      content: 'data(label)',
    },
    style: {
      'background-color': 'blue',
      label: 'data(label)',
    },
  },
  {
    selector: ':parent',
    style: {
      'background-opacity': 0.222,
    },
  },
  {
    selector: '#66021680-399e-4724-94b5-245a9fa7fb98',
    style: {
      'background-image':
        'https://upload.wikimedia.org/wikipedia/commons/b/b4/High_above_the_Cloud_the_Sun_Stays_the_Same.jpg',
      'background-fit': 'cover cover',
    },
  },
  {
    selector: ':selected',
    style: {
      'background-opacity': 0.111,
    },
  },
  {
    selector: '[! hasGoal ]',
    style: {
      'background-color': 'brown',
    },
  },
  {
    selector: '[id = "inbox"]',
    style: {
      'background-color': 'red',
    },
  },
  {
    selector: '[type = "resource"]',
    style: {
      'background-color': 'brown',
    },
  },
  {
    selector: '[type = "tool"]',
    style: {
      'background-color': 'purple',
    },
  },
  {
    selector: '[type = "goal"]',
    style: {
      'background-color': 'green',
    },
  },
  {
    selector: '[leaf = "true"]',
    style: {
      'background-color': 'red',
      shape: 'triangle',
      'background-opacity': 0.333,
    },
  },
  {
    selector: '[goal = "false"]',
    style: {
      shape: 'triangle',
    },
  },
  {
    selector: '[type = "problem"]',
    style: {
      shape: 'triangle',
      'background-color': 'red',
    },
  },
  {
    selector: '[type = "benefit"]',
    style: {
      'background-color': 'yellow',
    },
  },
  {
    selector: '[type = "assumption"]',
    style: {
      'background-color': 'pink',
    },
  },
  {
    selector: '[filter = "yes"]',
    style: {
      'background-color': 'purple',
    },
  },
  {
    selector: '[type = "filter"]',
    style: {
      'background-color': 'purple',
    },
  },
  {
    selector: '[type = "knowledge"]',
    style: {
      'background-color': 'orange',
    },
  },
  {
    selector: '.neighbourhood',
    style: {
      'border-width': 5,
      'border-color': 'green',
    },
  },
  {
    selector: '[qgate = "true"]',
    style: {
      // 'background-color': 'blue'
      shape: 'star',
    },
  },
  {
    selector: 'edge',
    css: {
      'curve-style': 'bezier',
      'target-arrow-shape': 'triangle',
      label: 'data(support)',
    },
  },
  {
    selector: '[support = "++"]',
    style: {
      'line-color': 'green',
      'target-arrow-color': 'green',
      width: 5,
      'line-style': 'solid',
    },
  },
  {
    selector: '[support = "--"]',
    style: {
      'line-color': 'red',
      'target-arrow-color': 'red',
      width: 5,
      'line-style': 'solid',
    },
  },
  {
    selector: '[support = "+"]',
    style: {
      'line-color': 'green',
      'target-arrow-color': 'green',
      width: 3,
      'line-style': 'dotted',
    },
  },
  {
    selector: '[support = "-"]',
    style: {
      'line-color': 'red',
      'target-arrow-color': 'red',
      width: 3,
      'line-style': 'dotted',
    },
  },

  // some style for the ext

  {
    selector: '.eh-handle',
    style: {
      'background-color': 'red',
      width: 12,
      height: 12,
      shape: 'ellipse',
      'overlay-opacity': 0,
      'border-width': 12, // makes the handle easier to hit
      'border-opacity': 0,
    },
  },

  {
    selector: '.eh-hover',
    style: {
      'background-color': 'red',
    },
  },

  {
    selector: '.eh-source',
    style: {
      'border-width': 2,
      'border-color': 'red',
    },
  },

  {
    selector: '.eh-target',
    style: {
      'border-width': 2,
      'border-color': 'red',
    },
  },

  {
    selector: '.eh-preview, .eh-ghost-edge',
    style: {
      'background-color': 'red',
      'line-color': 'red',
      'target-arrow-color': 'red',
      'source-arrow-color': 'red',
    },
  },

  {
    selector: '.eh-ghost-edge.eh-preview-active',
    style: {
      opacity: 0,
    },
  },
];

export const propertyTypes: {
  [key: string]: { responsible: string; [key: string]: string }
} = {
  responsible: { responsible: 'Person' },
};

interface property_type {
  name: string
  type: string
}
export interface field_type {
  type: string // type of data
  mandatory?: boolean // type of data
  checkIfValid: (value: Object) => boolean
  values: {
    dependingOn?: string | null // ie if a dropdown needs to look up values from some other index
    dropdowncontent?: 'technical' | null // if the values are technical (eg dictionary) or types from the graph
    type?: string // the type of representation (color, textfield, range)
    min?: null | number | string | object
    max?: null | number | string | object
    default: null | number | string | object
    elementtype?: null | string
    selected?: null | string | undefined | number | boolean
  }
}

export const initialState: InitialState = {
  meta: { original: [{}] },
  mappedTechnicalDependencies: {
    ['filter']: { ['value']: { ['label']: undefined } },
  },
  typeDefaults: {
    arrtest: {
      ffffff: {
        mandatory: true,
        type: 'text',
        array: true,
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: ['vala', 'valb'],
          selected: null,
        },
      },
    },
    filter: {
      label: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          dropdowncontent: 'technical',
          elementtype: true,
          type: 'dictionary.keys',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      comparison: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'test',
          selected: null,
        },
      },
      combinator: {
        // just edge
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'just for edge',
          selected: null,
        },
      },
      value: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          dependingOn: 'label',
          dropdowncontent: 'technical',
          elementtype: true,
          type: 'dictionary.values',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
    },
    // Define the "BusinessProcess" type
    knowledge: {
      label: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      description: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'test',
          selected: null,
        },
      },
    },
    // Define the "BusinessProcess" type
    todo: {
      label: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      location: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      description: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'thefoobar',
          min: '',
          max: null,
          default: 'test',
          selected: null,
        },
      },
      duration: {
        mandatory: true,
        type: 'interval',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'duration',
          min: '0 min',
          max: '10 min',
          default: '0 min',
          selected: '0 min',
        },
      },
      physical_effort: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'low',
          selected: null,
        },
      },
      mental_effort: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'medium',
          selected: null,
        },
      },
      date: {
        type: 'date',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'date',
          min: 'today',
          max: null,
          default: 'today',
          selected: 'today',
        },
      },
    },
    // Define the "BusinessProcess" type
    buy: {
      label: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      store: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'test',
          selected: null,
        },
      },
      quantity: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'text',
          min: '0',
          max: '100',
          default: '0',
        },
      },
    },
    // Define the "BusinessProcess" type
    code: {
      label: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      code_type: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'something',
          selected: null,
        },
      },
      belongs_to_class: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'self-',
          selected: null,
        },
      },
      contains_function: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          elementtype: 'code_type',
          type: 'function',
          min: '#000000',
          max: '#FFFFFF',
          default: true,
          selected: true,
        },
      },
      description: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: 'test',
          selected: null,
        },
      },
    },
    // Define the "BusinessProcess" type
    process: {
      anew: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          // changeFunction: foo => console.log('foo'),
          // changeFunction: (foo) => {const fun = () => {}; fun(foo)},
          changeFunction: (bar: any) => {
            const fun = (a: {
              foo: { cy: cytoscape.Core; content: Object }
            }) => {
              const processes = a.foo.cy
                .elements()
                .filter(':visible')
                .filter("[type = 'process']");
              console.log( 'current debug: the retrieval for process nodes is triggered', { processes } );

              return true;
            };
            fun(bar);
          },
          type: 'button',
          min: '',
          max: null,
          default: 'New Start',
          selected: null,
        },
      },
      name: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      description: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'thefoobar',
          min: '',
          max: null,
          default: 'The Description',
          selected: null,
        },
      },
      isChecked: {
        type: 'boolean',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'switch',
          min: '#000000',
          max: '#FFFFFF',
          default: 'true',
          selected: 'true',
        },
      },
      steps: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'chipfield',
          min: '',
          max: null,
          default: 'all steps',
          selected: null,
        },
      },
      process: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      wanting: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          elementtype: true,
          type: 'goal',
          min: '#000000',
          max: '#FFFFFF',
          default: true,
          selected: true,
        },
      },
      duration: {
        type: 'interval',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          type: 'color',
          min: '#000000',
          max: '#FFFFFF',
          default: '#000000',
          selected: '#5555FF',
        },
      },
    },
    // scqa: {
    //   situation: '',
    //   complication: '',
    //   question: '',
    //   answer: '',
    // },
    // qgate: {
    //   scqa: SCQA
    // },
    // goal:  // Define the "BusinessProcess" type
    //   {
    //     description: "",
    //     isCompleted: false,
    //     q25: QGate,
    //     q50: QGate,
    //     q75: QGate,
    //     q100: QGate,
    //   },
    requirement: {
      requirementID: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      feature: {
        mandatory: true,
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      required_testcase: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        // values: {min: {duration: 0, unit: 'm'}, max:null, default: {duration: 10, unit: 'm'}}
        values: {
          elementtype: true,
          type: 'testcase',
          min: '#000000',
          max: '#FFFFFF',
          default: true,
          selected: true,
        },
      },
      description: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      // source                      : RequirementSource,
      // priority                    : RequirementPriority, // Using the enum here
      // type                        : RequirementType, // Using the enum here
      // dependencies                : Tasks[],
      // constraints                  : Constraints[],
      // acceptanceCriteria          : AcceptanceCriterion[],
      // validationMethod            : ValidationMethod,
      associatedRisks: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
      // lifecycleStage              : LifecycleStage, // Using the enum here
      // changeHistory                : ChangeRecord[],
      // owner                        : {Person, Role},
      approvalReviewProcess: {
        type: 'text',
        checkIfValid: (value: { duration: number; unit: string }) =>
          value.duration > 0 && ['m', 'h', 'd', 'w'].includes(value.unit),
        values: {
          type: 'text',
          min: '',
          max: null,
          default: '',
          selected: null,
        },
      },
    },
  },
  styles,
  roles: ['default'],
  dictionary: [{}],
  filter: [{ nodes: {}, edges: {} }],
  highlight: [],
  theleft: [{ nodes: [], edges: [] }],
  uiList: [],
  structure: eles,
  current_page: 0,
  reportdata: [],
  carouselpages: [{ id: 0 }],
  customerWarning: {
    show: false,
    decision: null,
    content: { text: 'Are you sure?', list: [] },
  },
  promise: null,
  commonvalues: {},
};

export default function reducer(state = initialState, action: AnyAction): InitialState {
  switch (action.type) {
    case types.UPDATEDICT:
      console.trace('we are updating the dictionary', action.payload);

      const clonedDictionary = _.cloneDeep(state.dictionary);
      console.log( 'we are updating the dictionary - Cloned', clonedDictionary, 'page', state.current_page );

      clonedDictionary[state.current_page] = action.payload;

      console.log( 'we are updating the dictionary - done', 'added content', action.payload, 'page', state.current_page, 'cldic', clonedDictionary[state.current_page] );

      console.log('New Dictionary Here!', { ...clonedDictionary });
      return {
        ...state,
        dictionary: { ...clonedDictionary },
      };

    case types.REPLACETECHNICALDEPENDENCIES:
      // {type:string, dependingProp: string, dependingOn: string, dependingVals: string[]}

      console.trace('where changint ', { ap: action.payload });
      console.log('where changint log ', { ap: action.payload, state: state.mappedTechnicalDependencies });
      const { type, dependingProp, dependingOn, dependingVals } = _.cloneDeep(
        action.payload,
      );
      if (
        typeof action.payload.dependingProp !== 'undefined' &&
        action.payload.dependingProp &&
        typeof action.payload.dependingOn !== 'undefined' &&
        action.payload.dependingOn
      ) {
        console.log('regularly adding the new filter', { type, dependingOn, dependingProp, dependingVals });

        const xnewones = {
          ...state.mappedTechnicalDependencies,
          [type]: {
            ...state.mappedTechnicalDependencies[type],
            [dependingProp]: { [dependingOn]: dependingVals },
          },
        }

        // ensures to prevent endlessloop
        if (_.isEqual(xnewones, state.mappedTechnicalDependencies)){
          return state
        }else{
          // const foo = {...state.mappedTechnicalDependencies[type]??{}}
          return {
            ...state,
            mappedTechnicalDependencies: xnewones,
          };
        }
      } else {
        console.log('remove the value somehow', { type, dependingOn, dependingProp, dependingVals });
        // const foo = {...state.mappedTechnicalDependencies[type]??{}}
        return {
          ...state,
          mappedTechnicalDependencies: {
            ...state.mappedTechnicalDependencies,
            [type]: {
              ...state.mappedTechnicalDependencies[type],
              [dependingProp]: { [dependingOn]: dependingVals },
            },
          },
        };
      }

    case types.SETCOMMONVALUES:
      // {type:string, dependingProp: string, dependingOn: string, dependingVals: string[]}

      console.trace('commonvalues are set here ', { ap: action.payload });
      const bla = _.cloneDeep(action.payload);
      const nstt = {
        ...state,
        commonvalues: bla,
      };
      return nstt;

    case types.SHOWMETA:
      console.log('SHOWMETA provides this payload', {pl: action.payload})
      console.log('we are messing with meta here show meta', action.payload);
      console.trace('we are messing with meta here show meta');
      const meta = _.cloneDeep(action.payload);
      // add role if it doesnt exist yet
      // console.log('meta.original changes, highlight is updated', meta)

      // reduces all metas to a meta-id array
      // const highlight = meta.reduce((acc,onemeta) => acc.push(onemeta.id), [])
      console.trace('xxx meta changes showmeta', meta);

      // create an array which lists all of meta's ids
      const highlights = meta
        .filter((original: cytoscape.ElementDataDefinition) => typeof original.id !== 'undefined')
        .reduce((acc: string[], original: cytoscape.ElementDataDefinition) => [...acc, original.id], []);

        
      // @todo move to action?!
      console.log('these are the other com will build', {meta})
      const othercommonvalues = _.cloneDeep(buildCommonValuesAlternative(meta));
      console.log('these are the other com', {othercommonvalues})

      const nst: uiDuck.InitialState = {
        ...state,
        commonvalues: othercommonvalues,
        meta: { original: meta },
        highlight: highlights,
      };

      console.log('this is the new stuff', { nst });
      // adds the provided meta (array) to the originals
      return _.cloneDeep(nst);
    case types.WARNING: {
      console.log('we show the warning deletex', action.payload);

      // if (action.payload.show != state.show){
      //   action.payload.promise = helper.createCustomPromise()
      //   console.log('we changed the show warning deletex', action.payload)
      // }

      // uiDuck.dispatcher.set_promise(dispatch, sharedHelper.createCustomPromise())
      // const customPromiseClone = _.cloneDeep(helper.createCustomPromise())
      const cloned = _.cloneDeep(action.payload);
      

      /////
      ////
      /// for some reason this doesn't compile when the function is called, so instead I redeclare the functino here and it works... @todo
      //
      // 
      // const newpromise = helper.createCustomPromise()
      const newpromise = (<T = unknown, E = unknown>(
        resolverFunc: 
        | ((
          resolve: (value: T | PromiseLike<T>) => void,
          reject: (reason?: E) => void,
        ) => void)
        | null = null,
      ): {
        promise: Promise<T>;
        resolve: (value: T | PromiseLike<T>) => void;
        reject: (reason?: E) => void;
      } => {
        let resolveFunc!: (value: T | PromiseLike<T>) => void; // Use definite assignment assertion
        let rejectFunc!: (reason?: E) => void; // Use definite assignment assertion
      
        const promise = new Promise<T>((resolve, reject) => {
          resolveFunc = resolve;
          rejectFunc = reject;
      
          if (typeof resolverFunc === 'function') {
            resolverFunc(resolve, reject);
          }
        });
      
        return {
          promise,
          resolve: (value: T | PromiseLike<T>) => resolveFunc(value), // Explicitly using T | PromiseLike<T>
          reject: (reason?: E) => rejectFunc(reason), // No change needed here
        };
      })()

      //\\\\\\\


      // taken outside of return to have a deep equal check 
      const newWarning = {
        ...state.customerWarning,
        // overwrite if it has been provided in the payload - keep if it hasnt.
        ...(cloned.show !== undefined ? { show: cloned.show } : {}),
        ...(cloned.content !== undefined ? {
            content: {
              ...state.customerWarning.content,
              ...cloned.content,
            },
          }
          : {}),
        ...(cloned.decision !== undefined
          ? {
            decision: cloned.decision
          }
          : null),
        ...(cloned.promise !== undefined ? { promise: cloned.promise } : {}),
      }

      const hasToUpdate = !_.isEqual(state.customerWarning, newWarning)

      return {
        ...state,
        promise: hasToUpdate ? newpromise : state.promise,
        customerWarning: {...newWarning},
      };
    }
      
    case types.PROMISE:
      console.log('we show the promise deletex', action.payload);

      // if (action.payload.show != state.show){
      //   action.payload.promise = helper.createCustomPromise()
      //   console.log('we changed the show warning deletex', action.payload)
      // }

      const clonedd = _.cloneDeep(action.payload);
      return {
        ...state,
        promise: clonedd,
      };
    case types.UPDATETHELEFT:
      console.log('update theleft changes HELLOWORLD', { action, leftstate: state.theleft });
      // const theleft = action.payload

      const clonedLeft = _.cloneDeep(state.theleft);
      clonedLeft[state.current_page] = _.cloneDeep(action.payload);

      console.log('debughere theleft changes', clonedLeft);
      return {
        ...state,
        theleft: clonedLeft,
      };
    case types.UPDATEUILIST:
      console.trace('update uilist changes HELLOWORLD', { action, listleft: state.uiList });
      // const theleft = action.payload

      let thelist: uiDuck.InitialState['uiList'] = []
      if (typeof state.uiList !== 'undefined'){
        const clonedList = _.cloneDeep(state.uiList);
        const clonedPayload = _.cloneDeep(action.payload);

        const foo = clonedPayload.filter((item: InitialState['uiList']) => typeof item !== 'undefined').reduce(
          (acc: {[key: string]: cytoscape.NodeDefinition }, item: cytoscape.NodeDefinition) => {
            
            if (typeof item.data === 'undefined' || typeof item.data.id === 'undefined'){
              console.log('Foo is foo undefined', {item, data: item.data ?? 'nodata', id: item.data?.id ?? 'noid' , stack: new Error() })
              return {...acc}
            }
            console.log('Foo is foo', {item, id: item.data })
            console.log('Foo is foo', {item, id: item.data.id ?? '' })
            return { ...acc, [item.data.id!]: item! }
          },
          {},
        )

        clonedList[state.current_page] = {
          ...clonedList[state.current_page],
          ...foo,
        };
        console.log('debughere thelist changes', clonedList);
        thelist = clonedList
      }

      return {
        ...state,
        uiList: thelist,
      };
    case types.DELETEUILIST:
      console.trace('update uilist changes HELLOWORLD', { action, listleft: state.uiList });
      // const theleft = action.payload

      const clonedList2 = _.cloneDeep(state.uiList) ?? {[state.current_page]: {}};
      const clonedPayload2 = _.cloneDeep(action.payload);
      //  = {...clonedList[state.current_page], };

      console.log('before thelist deleted eles', { l: state.uiList, p: action.payload });
      // let foo = { keepThis: clonedList2[state.current_page], removeThis:  };

      // const { removeThis, ...newFoo } = foo;

      // clonedList2[state.current_page] = newFoo

      // // const remove = clonedPayload2.reduce((acc, item) => ({...acc, [item.id]: item}), {})

      // delete the payload-elements from the list

      const newFoo = clonedPayload2.reduce((result: {[key: string]: cytoscape.NodeDefinition }, item: cytoscape.NodeDefinition) => {
        const { [item.data.id!]: omit, ...rest } = result;
        return rest;
      }, clonedList2[state.current_page]);

      console.log('debughere thelist deleted eles', { elementsForUiList: newFoo.map((fo:any) => fo.data()) });
      return {
        ...state,
        uiList: newFoo,
      };
    case types.HIGHLIGHT:
      return {
        ...state,
        highlight: action.payload,
      };
    case types.BACKEND_ADDFILTER:
      console.log('Backend adds filter here', { state, pay: action.payload });

      const npl = _.cloneDeep(action.payload);
      console.log('Backend adds filter here x', { npl, nplnodes: npl.nodes });

      // console.log('npl was not changed here xx', { nnpl })

      // npl.nodes = nnpl

      // console.log('npl was changed here', {nnpl})
      console.log('npl was not changed here', { npl });
      return {
        ...state,
        // filter: nnpl,
        filter: npl,
      };
    case types.CONFIG_CHANGED:
      console.log('CONFIG CHANGED here', action.payload);

      return {
        ...state,
        config: action.payload,
      };
    case types.ADDFILTER:
      console.trace('x we filter filters here', { pl: action.payload, fi: state.filter });
      console.log('General adds filter here', { state, pay: action.payload });

      console.log('Before cloning', action.payload);
      const clonedAP : {crud: string, filterchain?: FilterChain , storageFilterCombo?: helper.StorageFilterCombo | helper.CyFilterCombo } = _.cloneDeep(action.payload);

      let newii: helper.StorageFilterCombo = { nodes: {}, edges: {} };
    
      console.log('In the reducer1', {fc: clonedAP.filterchain,  pl: action.payload})
      

      
      if (clonedAP.filterchain) {
        console.log('In the reducer2', { pl: action.payload})

        newii.nodes = Object.entries(clonedAP.filterchain)
          .reduce((acc, [filterKey, filterVal]) => {
            console.log('In the reducer', {filterKey, filterVal, pl: action.payload})
            const filteredState = Object.entries(state.filter[state.current_page])
              .filter(([stateKey]) => stateKey !== filterVal.filter.label)
              .reduce((res, [key, value]) => ({ ...res, [key]: value }), {});
    
            return {
              ...acc,
              [filterVal.filter.label ?? 'placeholder_a']: filterVal.filter,
            };
          }, {});
      }
    
      if (clonedAP.storageFilterCombo) {
        
          console.log('In the reducer2s', { cs:  clonedAP.storageFilterCombo})
  
        

        if (action.payload.crud === '___d'){
          // newii.nodes = Object.entries(clonedAP.storageFilterCombo.nodes)
          //   .reduce((acc, [filterKey, filterVal]) => {
          //     console.log('In the reducer', {filterKey, filterVal, pl: action.payload})
          //     const filteredState = Object.entries(state.filter[state.current_page])
          //       .filter(([stateKey]) => stateKey !== filterVal.label)
          //       .reduce((res, [key, value]) => ({ ...res, [key]: value }), {});
      
          //     return {
          //       ...acc,
          //       [filterVal.label ?? 'placeholder_a']: filterVal,
          //     };
          //   }, {});

          // iterates over state filters, compares each key with the payload and only takes the ones over which aren't present in the payload
          const newEdges = Object.fromEntries(
            Object.entries(state.filter[state.current_page].edges).filter(([key]) => !clonedAP.storageFilterCombo?.edges[key])
          );
        
          // iterates over state filters, compares each key with the payload and only takes the ones over which aren't present in the payload
          const newNodes = Object.fromEntries(
            Object.entries(state.filter[state.current_page].nodes).filter(([key]) => !clonedAP.storageFilterCombo?.nodes[key])
          );
          console.log('whatever this is', {clonedAP, newEdges, newNodes})
          newii = {edges: newEdges, nodes: newNodes}
        }else{
          // 'c_u_'
          newii = clonedAP.storageFilterCombo;
        }
      }
      
      console.log('this is newiinewii', {newii})

      console.log('Adding filter here', { state,  nf: { ...state.filter, [state.current_page]: { nodes: newii.nodes, edges: newii.edges } } });
      
      return {
        ...state,
        filter: {
          ...state.filter,
          [state.current_page]: {
            nodes: newii.nodes,
            edges: newii.edges,
          },
        },
      };
      

    case types.CHANGE_STYLES:
      console.log( 'Styles changes we change Styles here lookhere', action.payload, state.styles );

      const clonedstyles = _.cloneDeep(state.styles);
      // iterate through all style elements, take over potential selectors from payload
      // return array to be reduced

      let hit = 0;
      //@ts-ignore
      clonedstyles.map(clone => {
        if (typeof action.payload[clone.selector] !== 'undefined') {
          hit += 1;
          clone = action.payload[clone.selector];
        }
      });

      if (hit == 0) {
        // if it hasn't been found yet, update.
        clonedstyles.push(action.payload);
      }
      console.log('Styles did change to cloned', clonedstyles);

      return {
        ...state,
        styles: clonedstyles,
      };
    case types.BATCH_STYLES:
      console.log( 'Styles changes we change Styles here lookhere', {ap: action.payload, statestyles: state.styles} );

      const clonedstyles2 = _.cloneDeep(state.styles);
      

      // add all _pictures to css
      const newStyles = (action.payload as NeoReturnElements['props']['nodes'])
        .filter(node => {
          if (typeof node.data._picture !== 'undefined') {
            console.log('what is the data here', node.data);
          }

          return typeof node.data._picture !== 'undefined';
        })
        .map(node => ({
          selector: `#${node.data.id}`,
          style: {
            'background-image': node.data._picture,
            'background-fit': 'cover cover',
          },
        }));

      // restriction: only during load - then the original styles don't have any pictures in it.
      //@ts-ignore
      newStyles.map(style => {
        clonedstyles2.push(style);
      });

      console.log('Styles did change to cloned', clonedstyles2);

      if (typeof clonedstyles2 === 'undefined') {
        return { ...state };
      }

      return {
        ...state,
        styles: clonedstyles2,
      };
    case types.SAVE:
      return {
        ...state,
        // data: {
        //   nodes: action.payload.nodes,
        //   edges: action.payload.edges,
        // },
      };

    case types.REPORTDATA:
      console.log('adding report data', { old: state.reportdata, new: action.payload });

      const newone = {
        cols: [...Object.keys(action.payload[0])],
        rows: [...action.payload],
      };

      return {
        ...state,
        reportdata: [{ type: 'datagrid', data: newone }],
      };
    case types.LOAD:
      return {
        ...state,
        // data: {
        //   nodes: action.payload.nodes,
        //   edges: action.payload.edges,
        // },
      };
    case types.ARRIVED:
      console.log('Arrived', action, state);
      return { ...state
        // , isPinging: false 
      };
    case types.TURNPAGE:
      console.trace('Turning page now', action, state);

      // adds new filter if carousel changes to previously unset page
      let nf = state.filter;
      if (typeof state.filter[action.payload] === 'undefined') {
        nf = cloneDeep(state.filter);
        nf[action.payload] = {nodes: {}, edges:{}};
      }

      // adds new dictionary if carousel changes to previously unset page
      let ndx = state.dictionary;
      if (typeof state.dictionary[action.payload] === 'undefined') {
        ndx = cloneDeep(state.dictionary);
        ndx[action.payload] = {};
      }

      console.log('New Dictionary Here!', ndx);
      return {
        ...state,
        filter: nf,
        dictionary: ndx,
        current_page: action.payload,
      };

    case types.REMOVEPAGE:
      console.log('Removing page now', action, state);

      // removes filter
      let nf2 = state.filter;
      if (typeof state.filter[action.payload] === 'undefined') {
        nf2 = cloneDeep(state.filter);
        nf2.splice(action.payload.old, 1);
      }

      // removes dictionary if carousel changes to previously unset page
      let nd = state.dictionary;
      if (typeof state.dictionary[action.payload] === 'undefined') {
        nd = cloneDeep(state.dictionary);
        nd.splice(action.payload.old, 1);
      }

      // recalculate carousel pages
      const nc = cloneDeep(state.carouselpages);
      nc.pop();

      console.log('New Dictionary Here!', nd);
      return {
        ...state,
        filter: nf2,
        dictionary: nd,
        current_page: action.payload.new,
        carouselpages: nc,
      };

    case types.CAROUSEL:
      console.log('CAROUSEL page now', action, state);

      return { ...state, carouselpages: action.payload };

    default:
      return state;
  }
}

async function configCall(  action: AnyAction = {type: null, payload: null}) {
  const fetchData = async (action: AnyAction) => {
    console.log('Get Config from FS', action.payload);
    //    const res = await fetch('/api/neo/neo4j', {method: "GET"});
    const res = await fetch('/api/graph/config', { method: 'GET' });
    const json = await res.json();
    if (json.content) {
      return json.content;
    }
  };

  const data = await fetchData(action);
  console.log('Config provided', data);
  return {
    data,
  };
}

export const epics = {
  deleteDataEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.DELETE),
      operators.tap(a => console.log('Done extracting posts', a)),
      operators.map(a => ({ type: types.ARRIVED })),
      operators.catchError(err => {
        console.error('Another Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),
  updateWorkspaceEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.ADDFILTER),
      operators.tap(a =>
        console.log( 'Cypher Update User Workspace', userActionTypes.UPDATE_WORKSPACE, a, JSON.stringify(store.value.uiApp.filter) ),
      ),
      operators.map(a => ({
        type: userActionTypes.UPDATE_WORKSPACE,
        payload: { filter: _.cloneDeep(store.value.uiApp.filter) },
      })),
      operators.catchError(err => {
        console.error('Another Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),
  loadConfigEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.CONFIG),
      operators.tap(a =>
        console.log('Config Action Request', { a, store: JSON.stringify(store.value.uiApp) }),
      ),

      // throw new Error('proceed here: load config from config-api, store config in redux config area, graphCy to retrieve config')

      operators.switchMap(action => {
        // switchmap allows switching the mapping from the input to another observable which is created in here
        const response = configCall(action);
        // creating the new observable
        return response;
      }),
      operators.tap(a => console.log('Done extracting roles', a)),
      operators.map(a => ({ type: types.CONFIG_CHANGED, payload: a.data })),
      operators.catchError(err => {
        console.error('Another Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),
};
