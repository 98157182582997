import { ofType, StateObservable } from 'redux-observable';
import { useDispatch, useSelector } from 'react-redux';
import * as rxjs from 'rxjs';
import * as operators from 'rxjs';
import { Observable } from 'rxjs';
import * as uiDuck from './uiDuck';
import { Elements, NeoReturnElements, ToolEventTypes } from '../../types/types';
import { Action, AnyAction, Dispatch } from 'redux';
import { RootState } from '../rootState';
import _ from 'lodash';

// const dispatch = useDispatch();
export const name = 'backend';
export const types = {
  NAME: `${name}`,
  //CRUD_PROCESSDATA: `backendandui/CRUD_PROCESSDATA`,  // explicitly no name set to run action in both
  CRUD_PROCESSDATA: `${name}/CRUD_PROCESSDATA`,
  CRUD_PROCESSDATA_EPIC: `${name}/CRUD_PROCESSDATA_EPIC`,
  LOAD: `${name}/LOADING`,
  SAVE: `${name}/SAVING`,
  STARTACTION: `${name}/STARTACTION`,
  ENDACTION: `${name}/ENDACTION`,
  DELETE: `${name}/DELETING`,
  DELETE_DONE: `${name}/DELETED`,
  MARKSAVE: `${name}/MARKSAVE`, // build a info collection for the sole purpose of ui info of to-be-saved elements
  ARRIVED: `${name}/ARRIVED`,
  ADD_CATEGORY_REQUEST: `${name}/ADD_CATEGORY_REQUEST`,
  ADD_CATEGORY_SUCCESS: `${name}/ADD_CATEGORY_SUCCESS`,
  GET_CATEGORIES_REQUEST: `${name}/GET_CATEGORIES_REQUEST`,
  ADD_CATEGORY_ERROR: `${name}/ADD_CATEGORY_ERROR`,
  START_BANK_STREAM: `${name}/START_BANK_STREAM`,
  BANK_STREAM_MESSAGE: `${name}/BANK_STREAM_MESSAGE`,
  STOP_BANK_STREAM: `${name}/STOP_BANK_STREAM`,

  SAVEMETA: `${name}/SAVEMETA`,
  GETROLES: `${name}/GETROLES`,
  GOTROLES: `${name}/GOTROLES`,
  GRAPH_UPDATED: `${name}/GRAPH_UPDATED`,
  INDEXES: `${name}/INDEXES`,
  HELLOWORLD: `${name}/HELLOWORLD`,
};

// selector functions
export const select = {
  // contains the data loaded from the db
  data: (state: RootState) => state[`${name}App`].data,
  processdata: (state: RootState) => state[`${name}App`].processdata,

  // defines what action is going on (load|save)
  inaction: (state: RootState) => state[`${name}App`].inaction,
  // which eles will be saved
  markedToSave: (state: RootState) => state[`${name}App`].markedToSave,
  // indexes of data elements, on whole store - not page dependent
  indexes: (state: RootState) => state[`${name}App`].indexes,
};

export const dispatcher = {
  saveFunction: (dispatch: Dispatch, value: Object) => {
    console.log('value to props', value);
    dispatch(actions.saveClick(value));
    // dispatch(neoActions.loadClick(value));
  },
  loadingElements: (dispatch: Dispatch) => {
    console.log('Loading to props');
    dispatch(actions.loadClick());
  },
  deleteThese: (dispatch: Dispatch, value: cytoscape.CollectionReturnValue) => {
    dispatch(actions.deleteClick(value));
  },
  /**
   * spreads the value (an array) and adds {id:label} objects
   * @param dispatch
   * @param value
   */
  markToSave: (
    dispatch: Dispatch,
    value: { [key: string]: cytoscape.ElementDataDefinition },
  ) => {
    console.log('the value handed over', {value, ty: _.isArray(value)})
    dispatch(actions.markToSaveAction(value));
  },
  /**
   * allows free access to indexes.
   * to add new index `foo`, payload should be `{foo:{val1:'val1'}}`
   * to add new element to index `foo`, payload should be `{...foo, val2:'val2'}`
   */
  indexes: (dispatch: Dispatch, value: { [id: string]: any }[]) => {
    dispatch(actions.indexes(value));
  },
  /**
   * change the processdata
   */
  logEvent: (dispatch: Dispatch, value: ToolEventTypes.Event) => {
    dispatch(actions.logEvent(value));
  },
};

// action creator
export const actions = {
  logEvent: (value: ToolEventTypes.Event | {} = {}) => {
    // triggers epic, epic reroutes to CRUD_PROCESSDATA
    console.log('crud process epic', value);
    return { type: types.CRUD_PROCESSDATA_EPIC, payload: value };
  },
  loadClick: (value: Object = {}) => {
    console.log('loadClick ', value, types.LOAD);
    return { type: types.LOAD, payload: value };
  },
  saveClick: (value: Object) => {
    console.log('save Click ', value, types.SAVE);
    return { type: types.SAVE, payload: value };
  },
  deleteClick: (value: cytoscape.CollectionReturnValue) => {
    console.log('delete click ', value, types.DELETE);
    return { type: types.DELETE, payload: value };
  },
  // marks to save several changed elements at once.
  markToSaveAction: (value: { [key: string]: cytoscape.ElementDataDefinition }) => {
    console.log('Mark to Save Action ', value, types.MARKSAVE);
    return { type: types.MARKSAVE, payload: value };
  },
  // updates the index - index has to be calculated separately before (helper)
  indexes: (value: { [id: string]: any }[]) => {
    console.log('Indexes Action ', value, types.INDEXES);
    return { type: types.INDEXES, payload: value };
  },
  saveMeta: (value: Object) => ({ type: types.SAVEMETA, payload: value }),

  update_nodes: (value: Object) => {
    console.log('GRAPH_UPDATED Nodes click ', value, types.GRAPH_UPDATED);

    return { type: types.GRAPH_UPDATED, payload: value };
  },
  update_edges: (value: Object) => {
    console.log('GRAPH_UPDATED edges click ', value, types.GRAPH_UPDATED);
    return { type: types.GRAPH_UPDATED, payload: value };
  },
  helloworld: (value: Object) => {
    console.log('HELLOWORLD', value, types.HELLOWORLD);
    return { type: types.HELLOWORLD, payload: value };
  },
};

const eles = { nodes: [], edges: [] };

export interface Processdata {
  process: string
  variant?: string
  step: string
  event: string
}
export interface InitialState {
  isPinging: boolean
  pong: { countries: string[] }
  data: Elements
  processdata: Processdata[]
  distinctRoles: { records: string[] }
  inaction: { save: string; load: string }
  theleft: Elements
  markedToSave: { [key: string]: cytoscape.ElementDataDefinition } // {element-id: element-label}
  indexes: {}
}

// reducer's initial state
export const initialState: InitialState = {
  isPinging: false,
  pong: { countries: [] },
  data: eles,
  processdata: [],
  distinctRoles: { records: [] },
  inaction: { save: '', load: '' }, // eg {load:{'active'},save:{'failed'}},
  theleft: eles,
  markedToSave: {},
  indexes: {},
  // dataforcy: eles,
};

export default function reducer(state = initialState, action: AnyAction): InitialState {
  // console.trace('were now reducing backend',{state,action })
  switch (action.type) {
    case types.CRUD_PROCESSDATA:
      console.log('we CRUD_PROCESSDATA here to nodes', action.payload, state);
      // state.meta.original
      return {
        ...state,
        processdata: action.payload,
      };
    case types.SAVEMETA:
      console.log('we save here to nodes', action.payload, state);
      // state.meta.original
      return {
        ...state,
      };
    case types.MARKSAVE:
      const clonedap = _.cloneDeep(action.payload)
      console.log('mark to save starts', { ap: action.payload, state: state.markedToSave , stack: new Error()});
      if (action.payload == -1) {
        // Remove 'markedToSave' from the state
        return {
          ...state,
          markedToSave: {...initialState.markedToSave},
        };
      }
      console.log('we mark to save save elements', { tap: Object.keys(clonedap), ts: Object.keys(state.markedToSave) , ap: clonedap, state: state.markedToSave, new: { ...state.markedToSave, ...clonedap } });

      // const news  = Object.keys(state.markedToSave).map(firstLvlIndex => )
      
      // const merged = Object.keys(state.markedToSave).reduce((acc, key) => {
      //   acc[key] = { ...state.markedToSave[key], ...clonedap[key] };
      //   return acc;
      // }, {} as Record<string, any>);

      const foo = { ...state.markedToSave, ...clonedap }
      console.log('these are the new values for mts', {foo})
      return {
        ...state,
        markedToSave: foo,
      };
    case types.HELLOWORLD:
      console.log('HELLOWORLD from reducer', action.payload, state);
      // state.meta.original
      return {
        ...state,
      };
    case types.INDEXES:
      console.log('INDEXES from reducer', { pay: action.payload, state, str: JSON.stringify(action.payload) });

      // Use filter to remove entries with foo === -1
      const filteredPayload = Object.entries({
        ...state.indexes,
        ...action.payload,
      }).reduce(
        (acc, [key, value]) => (value === -1 ? acc : { ...acc, [key]: value }),
        {},
      );

      // action.payload has to be an object with keys as index names
      return {
        ...state,
        indexes: filteredPayload,
      };
    case types.STARTACTION:
      console.log('Action is starting', action.payload, state);

      //      return { ...state, isPinging: false, pong: action.payload.countries?action.payload.countries:{} };
      return { ...state, inaction: action.payload };
    // return state
    case types.ENDACTION:
      console.log('Action is completed', action, state);
      if (action.payload.data === null) {
        // save is done - thus reset markedToSave
        console.log('Payload is empty, we saved', action, state);
      
        return {
          ...state,
          markedToSave: initialState.markedToSave,
          inaction: action.payload.status,
        };
      }


      const newdata = action.payload.data;
      //      return { ...state, isPinging: false, pong: action.payload.countries?action.payload.countries:{} };
      console.log('The new Data loaded', {newdata})
      //    The new Data loaded { newdata: { nodes: [ [Object], [Object] ], edges: [ [Object] ] } }

      return { ...state, inaction: action.payload.status, data: newdata };
    // return state
    case types.ARRIVED:
      console.log('Arrived', action, state);
      return { ...state, isPinging: false };
    case types.GRAPH_UPDATED:
      //	console.log('why is useSelector not updating even though === false???? old state vs new state', state.theleft, action.payload, state.theleft === action.payload );
      console.log( 'old state vs new state', state, state.theleft, action.payload, state.theleft === action.payload );

      // creates new reference which in turn triggers useSelector to update the item
      const theleft2 = action.payload;

      console.log('Grahp was updated', action, state, theleft2);
      return { ...state, theleft: theleft2 };
    case types.GOTROLES:
      console.log('Arrived', action, state);
      return { ...state, distinctRoles: action.payload };
    case types.ADD_CATEGORY_ERROR:
      console.log('Error Handling', action, state);
      return { ...state, inaction: action.payload };
    default:
      return state;
  }
}

async function fetchIndex() {
  const fetchData = async () => {
    console.log('Fetch Index / Dictionary');
    const res = await fetch('/api/neo/dictionary', { method: 'GET' });
    const json = await res.json();
    if (json.content) {
      return json.content;
    }
    // throw new Error('No content available');
  };
  const data = await fetchData();
  console.log('Fetch Index provided Data', data);
  return {
    props: data,
  };
}

async function crudProcessMaria(action: AnyAction = {type: null, payload: null}, method = 'PUT') {
  console.log('Here is the crud start to maria pre', { action, method });
  const data = action?.payload;
  console.log('Here is the crud start to maria', { data });
  try {
    const getData = async (payload: Object) => {
      // const url = `api/v1/maria/event/?process_uuid=1&process_variant=2&log_id=0`
      const url = 'api/v1/maria/event';

      const res = await fetch(url, {
        method,
        body: JSON.stringify(payload),
      });
      const json = await res.json();
      console.log('Here is the crud json', { method, json, res });
      if (json.result) {
        return { payload: json.result };
      } else {
        return { payload: [] };
      }
    };
    const data2 = await getData(data);
    console.log('Here is the crud from maria', { method, data2 });
    return data2;
  } catch (error) {
    console.log('Fetch Index Failed Error', { error });
    return rxjs.EMPTY; // Return an empty observable when the condition is not satisfied

    // throw error
  }
}

// Combined fetchData function for GET and POST requests
const fetchDataNew = async (action: AnyAction = {type: null, payload: null}) : Promise<NeoReturnElements> => {
  console.log('Trigger Fetch Data New', { action });

  let method: string;
  let body: any;
  console.log('Trigger Fetch Data New 1', { action });
  if (action.type === types.LOAD) {
    console.log('Trigger Fetch Data New 2', { action });
    method = 'GET';
    body = null;
  } else if (action.type === types.SAVE) {
    console.log('Trigger Fetch Data New 3', { action });
    method = 'POST';
    body = JSON.stringify(action.payload);
  } else {
    console.log('Trigger Fetch Data New 4', { action });
    throw new Error(`Only GET and POST but not: ${action.type}`);
  }
  console.log('Trigger Fetch Data New 5', { action });
  const fetchData = async (action: AnyAction) => {
    console.log('Trigger Fetch Data New 6 1', { action, body });

    // console.log('Fetch Post Post Post Get', {ap: action.payload, daata: action.payload.nodeJson[0].data});

    console.log('Trigger Fetch Data New 6 2', { action, body });
    const res = await fetch('/api/neo/neo4j', { method, body });
    console.log('Trigger Fetch Data New 6 3', { action, res });
    console.log('Json Content Response past', { action });
    console.log('result of post', res);

    try {
      const json = await res.json();
      if (json.content) {
        console.log('Success Json Content Response', { action, content: json.content, res });
        return json.content;
      } else {
        console.log('No Json Content Response', { action, res, json });
      }
      
    } catch (error) {
      console.log('Failed to extract json', error);
    }
  };

  console.log('Trigger Fetch Data New 6', { action });
  const data = await fetchData(action);

  console.log('Trigger Fetch Data New 7', { action , data });
  console.log('Data FetchData provided', { action, data });

  return {
    props: {
      nodes: Object.values(data.nodes).map(node => (node as cytoscape.NodeDefinition) ),
      edges: Object.values(data.edges).map(edge => (edge as cytoscape.EdgeDefinition) ),
    }
  }
}

async function getRoles(action: AnyAction = {type: null, payload: null}) {
  const fetchData = async (action: AnyAction) => {
    console.log('Get Roles from Neo', action.payload);
    //    const res = await fetch('/api/neo/neo4j', {method: "GET"});
    const res = await fetch('/api/neo/meta', { method: 'GET' });
    const json = await res.json();
    if (json.content) {
      return json.content;
    }
  };
  const data = await fetchData(action);
  console.log('Roles provided', data);
  return {
    data,
  };
}

async function fetchDelete(action: AnyAction = {type: null, payload: null}) {
  const fetchData = async (action: AnyAction) => {
    console.log('Delete Post Post Post', {ap: action.payload, eltodel: action.payload.map((el:any) => el.data())});
    //    const res = await fetch('/api/neo/neo4j', {method: "GET"});
    const res = await fetch('/api/neo/neo4j', {
      method: 'DELETE',
      body: JSON.stringify(action.payload),
    });
    //  const res = await fetch('/api/neo/neo4j', {method: "POST", body: JSON.stringify(action.payload)});
    const json = await res.json();
    if (json.content) {
      return json.content;
    }
  };
  const data = await fetchData(action);
  console.log('Data FetchData provided', data);
  return {
    props: {
      edges: data.edges,
      nodes: data.nodes,
    },
  };
}

export const epics = {
  deleteNeoEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.DELETE),
      operators.tap(a => console.log('Start DELETE posts', a)),
      operators.switchMap(action => {
        console.log('switch map', action);
        // switchmap allows switching the mapping from the input to another observable which is created in here
        const response = fetchDelete(action);
        console.log('soweit', response);
        // creating the new observable

        return response;
      }),
      operators.tap(a => console.log('Done geting posts', a)),
      operators.map(a => ({ type: types.DELETE_DONE })),
      operators.catchError(err => {
        console.error('Another Delete Hoppla!', err);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),

  // in case that a filter matches, the current action is set.
  // controlling the action itself is done elsewhere
  identifyActionEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => {
        if (action.type === types.SAVE) {
          return true;
        }
        if (action.type === types.LOAD) {
          return true;
        }
        return false
      }),
      operators.map(action => {
        let action_name = '';
        if (action.type === types.SAVE) {
          action_name = 'save';
        }
        if (action.type === types.LOAD) {
          action_name = 'load';
        }
        return action_name;
      }),
      operators.tap(a => console.log('Identifying Action:', a)),
      operators.map(a => ({
        type: types.STARTACTION,
        payload: { [a]: 'active' },
      })),
      operators.catchError(err => {
        console.error('Another Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),

  operateNeoEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(
        action => action.type === types.SAVE || action.type === types.LOAD,
      ),
      operators.tap(action =>
        console.log(`${action.type} Action Happens`, action),
      ),
      operators.switchMap(action =>
        // GET or POST to Neo4j
        operators.from(fetchDataNew(action)).pipe(
          operators.tap(response =>
            console.log('Fetch response received', response),
          ),
          operators.switchMap(response => {
            if (action.type === types.LOAD) {
              return operators.merge(
                operators
                  .from(fetchIndex())
                  .pipe(
                    operators.filter(response => typeof response === 'object' &&  response !== null && 'props' in response),
                    operators.map(response => actions.indexes(response.props))
                  ),
                operators.of(uiDuck.actions.updateTheLeft(response.props)),
                operators.of(
                  uiDuck.actions.updateUiListElements(
                    Object.values(response.props.nodes).map(val => val),
                  ),
                ),
                operators.of(uiDuck.actions.batchStyle(response.props.nodes)),
                operators.of({
                  type: types.ENDACTION,
                  payload: { status: { load: '' }, data: response.props },
                }),
              );
            } else {
              return operators.of({
                type: types.ENDACTION,
                payload: { status: { save: '' }, data: null },
              });
            }
          }),
          operators.catchError(err => {
            console.error(`${action.type} Error`, err.message);
            return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
          }),
        ),
      ),
    ),

  distinctRolesEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.GETROLES),
      operators.tap(a => console.log('Start Extracting Roles', a)),
      operators.switchMap(action => {
        // switchmap allows switching the mapping from the input to another observable which is created in here
        const response = getRoles(action);
        // creating the new observable
        return response;
      }),
      operators.tap(a => console.log('Done extracting roles', a)),
      operators.map(a => ({ type: types.GOTROLES, payload: a.data })),
      operators.catchError(err => {
        console.error('Another Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),

  crudProcessdataEpic: (action$: Observable<Action>, store: StateObservable<RootState>) =>
    action$.pipe(
      operators.filter(action => action.type === types.CRUD_PROCESSDATA_EPIC),
      operators.tap(a => console.log('Now running crudProcess 1', { a })),
      operators.tap(a => console.log('Now running crudProcess 1.51', { a })),

      // process each
      operators.concatMap(action =>
        operators.from(crudProcessMaria(action)).pipe(
          // operators.ignoreElements(),
          // operators.startWith(action),
          operators.tap(a => console.log('Now running crudProcess 2.5', { a })),
          operators.tap(a =>
            console.log('Debug: after crudProcessMaria with action', { action, a }),
          ),

          // Process the response from crudProcessMaria(action)
          operators.concatMap(response => {
            // Now call crudProcessMaria with null, 'GET'
            return operators.from(crudProcessMaria(undefined, 'GET')).pipe(
              operators.tap(a =>
                console.log('Now running crudProcess 2.75', { a }),
              ),
              operators.map(responseGET => {
                // Return the combined result
                return {
                  action,
                  responseGET,
                };
              }),
            );
          }),
        ),
      ),

      // Process the combined responses
      operators.tap(a =>
        console.log('Debug: after crudProcessMaria with null, GET', { a }),
      ),
      // operators.concatMap(({ response, responseGET }) =>
      operators.concatMap(({  responseGET }) =>
        operators
          .from([
            operators.of({
              type: uiDuck.types.REPORTDATA,
              // payload: responseGET.payload, // Same here
              payload: responseGET, // Same here
            }),
          ])
          .pipe(
            operators.tap(a =>
              console.log('Now running crudProcess 2', { a, b: store.value.backendApp.processdata }),
            ),
            operators.concatMap(actionObservable => actionObservable),
          ),
      ),

      operators.catchError(err => {
        console.error('crudProcessdataEpic Another Get Hoppla!', err.message);
        return rxjs.of({ type: types.ADD_CATEGORY_ERROR, payload: err });
      }),
    ),
};
